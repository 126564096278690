.premises:hover {
  background-color: #eeffee;
}
.category-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 12px;

  font-weight: bold;
  font-size: 16px;
  color: #000;

  background-color: #fff;
  border-bottom: 1px solid #ddd;
  outline: none;

  cursor: pointer;
}

.category-item:hover {
  background-color: #eeffee;
}

.search-bar {
  -webkit-flex: 1 1;
          flex: 1 1;
  border: none;
  display: -webkit-flex;
  display: flex;
  border-radius: 0;
  font-size: 16px;
  padding: 16px;
}

.search-bar:focus {
  outline: none;
}

.product-editor {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.product-editor:hover {
  background-color: #eeffee;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

