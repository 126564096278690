.category-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;

  font-weight: bold;
  font-size: 16px;
  color: #000;

  background-color: #fff;
  border-bottom: 1px solid #ddd;
  outline: none;

  cursor: pointer;
}

.category-item:hover {
  background-color: #eeffee;
}

.search-bar {
  flex: 1;
  border: none;
  display: flex;
  border-radius: 0;
  font-size: 16px;
  padding: 16px;
}

.search-bar:focus {
  outline: none;
}

.product-editor {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.product-editor:hover {
  background-color: #eeffee;
}